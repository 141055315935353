











































































































import { Component, Mixins } from 'vue-property-decorator';
import { projectModule } from '@/store/modules/project';
import Price from './Filter/Price.vue';
import Bedroom from './Filter/Bedroom.vue';
import Bathroom from './Filter/Bathroom.vue';
import ProjectPhase from './Filter/ProjectPhase.vue';
import Area from './Filter/Area.vue';
import ViewFilter from './Filter/ViewFilter.vue';
import Financing from './Filter/Financing.vue';
import Applied from './Filter/Applied.vue';
import Location from './Filter/Location.vue';
import ProjectType from './Filter/ProjectType.vue';
import TypologyTypes from './Filter/TypologyTypes.vue';
import Condition from './Filter/Condition.vue';
import Commission from './Filter/Commission.vue';
import FilterMixin from '@/mixins/filter';

@Component({
    components: {
        Price,
        Area,
        Bedroom,
        Bathroom,
        ProjectPhase,
        ViewFilter,
        Financing,
        Applied,
        Location,
        ProjectType,
        TypologyTypes,
        Condition,
        Commission
    }
})
export default class MobileFilter extends Mixins(FilterMixin){
    get dialog() {
        return projectModule.dialogs;
    }

    menus = [
        {
            component: ProjectType,
            open: false,
            title: 'Tipo de Inmueble',
            name: 'projectTypes',
            filter: { value: undefined, label: '' }
        },
        {
            component: Price,
            open: false,
            title: 'Precio',
            name: 'price',
            filter: { value: undefined, label: '' }
        },
        {
            component: TypologyTypes,
            open: false,
            title: 'Tipología',
            name: 'typologyTypes',
            filter: { value: undefined, label: '' }
        },
        {
            component: Bedroom,
            open: false,
            title: 'Dormitorios',
            name: 'bedrooms',
            filter: { value: undefined, label: '' }
        },
        {
            component: Area,
            open: false,
            title: 'Área total',
            name: 'meters',
            filter: { value: undefined, label: '' }
        },
        {
            component: Bathroom,
            open: false,
            title: 'Baños',
            name: 'bathrooms',
            filter: { value: undefined, label: '' }
        },
        {
            component: ViewFilter,
            open: false,
            title: 'Vista',
            name: 'orientation',
            filter: { value: undefined, label: '' }
        },
        {
            component: ProjectPhase,
            open: false,
            title: 'Etapa del proyecto',
            name: 'projectPhases',
            filter: { value: undefined, label: '' }
        },
        {
            component: Financing,
            open: false,
            title: 'Financiamiento',
            name: 'financeBanks',
            filter: { value: undefined, label: '' }
        },
        {
            component: Condition,
            open: false,
            title: 'Comisión',
            name: 'commission',
            filter: { value: undefined, label: '' }
        },
        {
            component: Commission,
            open: false,
            title: 'Condición de pago',
            name: 'condition',
            filter: { value: undefined, label: '' }
        }
    ];

    clean() {
        this.cleanFilters();
    }

    change() {
        const filter = this.menus.reduce((obj, element) => {
            if (element.filter && !Array.isArray(element.filter.value)) {
                for (const [key, value] of Object.entries(
                    element.filter.value as any
                )) {
                    obj[key] = value;
                }
            } else if (element.filter && element.filter.value) {
                obj[element.name] = element.filter.value;
            }

            return obj;
        }, {} as any);
        this.setFilter(filter);
        this.close();
    }

    close() {
        projectModule.closeOrOpenDialog('filter');
    }
}
