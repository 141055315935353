


























import { Component, Mixins, Watch, Emit } from 'vue-property-decorator';
import FilterMixin from '@/mixins/filter';
import { phaseProject } from '@/filters/phase-project';

@Component
export default class ProjectPhase extends Mixins(FilterMixin) {
    private phase: number[] = [];
    private phases = [
        { value: 1, label: 'En Planos' },
        { value: 2, label: 'En Construcción' },
        { value: 3, label: 'Entrega Inmediata' }
    ];

    @Watch('filters.projectPhases', {
        immediate: true,
        deep: true
    })
    filter(value: number[]) {
        this.phase = value;
    }

    @Watch('phase', {
        immediate: true,
        deep: true
    })
    handleValue() {
        let label = '';
        if (this.phase.length === this.phases.length) {
            label = 'Todos';
        } else if (this.phase.length > 0) {
            label = this.phase.map(x => phaseProject(x)).join(', ');
        }

        this.filterString(label);
    }

    @Emit() filterString(label: string) {
        return { value: this.phase, label };
    }

    clear() {
        this.setFilter({
            projectPhases: []
        });
    }

    changeFilter() {
        this.setFilter({
            projectPhases: this.phase
        });
    }
}
