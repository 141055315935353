



















































































































































































import { Component, Mixins } from 'vue-property-decorator';
import ProjectMixin from '@/mixins/project';
import TableModel from './TableModel.vue';
import ActivateBedroomFilterMixin from '@/mixins/activate-bedroom-filter';
import { hiddenBedroom } from '@/filters/hidden-bedroom';

@Component({
    components: { TableModel },
    filters: { hiddenBedroom }
})
export default class ProjectDetail extends Mixins(
    ProjectMixin,
    ActivateBedroomFilterMixin
) {
    enter() {
        const routeData = this.$router.resolve({
            path: this.link,
            query: this.filter as any
        });
        window.open(routeData.href, '_blank');
    }
}
