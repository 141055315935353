

































import { Component, Mixins, Watch } from 'vue-property-decorator';
import FilterMixin from '@/mixins/filter';
import { phaseProject } from '@/filters/phase-project';

@Component
export default class Commission extends Mixins(FilterMixin) {
    commission: { min?: number; max: number } = {
        min: 0,
        max: 0
    };

    @Watch('filters.commission', {
        immediate: true,
        deep: true
    })
    filter([min, max]: number[]) {
        this.commission = {
            min: min < 1 ? undefined : min,
            max: max
        };
    }

    @Watch('commission', {
        immediate: true,
        deep: true
    })
    handleValue() {
        let label = '';
        const [min, max] = this.parseValue();
        if (min > 0 && !max) {
            label = `Desde ${min}`;
        } else if (!min && max) {
            label = `Hasta ${max}`;
        } else if (min && max) {
            label = `${min} - ${max}`;
        }

        this.$emit('filterString', {
            value: this.parseValue(),
            label
        });
    }

    clear() {
        this.setFilter({
            commission: this.parseValue()
        });
    }

    changeFilter() {
        this.setFilter({
            commission: this.parseValue()
        });
    }

    parseValue() {
        const value = [];
        const init = this.commission.min
            ? parseInt(this.commission.min.toString())
            : undefined;
        const end = this.commission.max
            ? parseInt(this.commission.max.toString())
            : undefined;

        if ((init && init > 0) || (end && end > 0)) value.push(init || 0);
        if (end && end > 0) value.push(end);
        return value;
    }
}
