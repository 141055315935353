import { Vue, Component } from 'vue-property-decorator';
import { projectModule } from '@/store/modules/project';
import { ProjectTypes } from '@/interfaces/project-type';

@Component
export default class ActivateBedroomFilterMixin extends Vue {
    get activateBedroomFilter() {
        return projectModule.filter.projectTypes?.some(type =>
            [ProjectTypes.House, ProjectTypes.Department].includes(type)
        );
    }
}
