

























import { Component, Mixins, Watch, Emit } from 'vue-property-decorator';
import FilterMixin from '@/mixins/filter';
import { FilterProject } from '@/store/modules/project/types';

@Component
export default class ViewFilter extends Mixins(FilterMixin) {
    private orientation: string[] = [];
    private orientations = [
        { value: 'EXTERIOR', label: 'Exterior' },
        { value: 'INTERIOR', label: 'Interior' }
    ];

    @Watch('filters.orientation', {
        immediate: true,
        deep: true
    })
    filter(value: string[]) {
        this.orientation = value;
    }

    @Watch('orientation', {
        immediate: true,
        deep: true
    })
    handleValue() {
        let value = '';
        if (this.orientation.length === this.orientations.length) {
            value = 'Todos';
        } else if (this.orientation.length > 0) {
            value = this.orientation.join(', ');
        }

        this.filterString(value);
    }

    @Emit() filterString(label: string) {
        return { value: this.orientation, label };
    }

    clear() {
        this.setFilter({
            orientation: []
        });
    }

    changeFilter() {
        this.setFilter({
            orientation: this.orientation
        });
    }
}
