




































import { Component, Mixins, Watch, Emit } from 'vue-property-decorator';
import FilterMixin from '@/mixins/filter';

@Component
export default class Bedroom extends Mixins(FilterMixin) {
    private bedroom: { minBedroom?: number; maxBedroom?: number } = {
        minBedroom: 0,
        maxBedroom: 0
    };

    @Watch('filters.bedrooms', {
        immediate: true,
        deep: true
    })
    filter([minBedroom, maxBedroom]: number[]) {
        this.bedroom = {
            minBedroom: minBedroom < 1 ? undefined : minBedroom,
            maxBedroom: maxBedroom
        };
    }

    @Watch('bedroom', {
        immediate: true,
        deep: true
    })
    handleValue({ minBedroom, maxBedroom }: any) {
        let value = '';
        const [min, max] = this.parseValue();
        if (min > 0 && !max) {
            value = `Desde ${min} dormitorios`;
        } else if (!min && max) {
            value = `Hasta ${max} dormitorios`;
        } else if (min && max) {
            value = `${min} - ${max} dormitorios`;
        }

        this.filterString(value);
    }

    @Emit() filterString(label: string) {
        return {
            value: this.parseValue(),
            label
        };
    }

    clear() {
        this.setFilter({
            bathrooms: []
        });
    }

    changeFilter() {
        this.setFilter({
            bedrooms: this.parseValue()
        });
    }

    changeMin() {
        if (this.bedroom.minBedroom) {
            this.bedroom.minBedroom =
                this.bedroom.minBedroom < 0 || this.bedroom.minBedroom > 10
                    ? undefined
                    : this.bedroom.minBedroom;
        }
    }

    changeMax() {
        if (this.bedroom.maxBedroom) {
            this.bedroom.maxBedroom =
                this.bedroom.maxBedroom < 0 || this.bedroom.maxBedroom > 10
                    ? undefined
                    : this.bedroom.maxBedroom;
        }
    }

    parseValue() {
        const value = [];
        const init = this.bedroom.minBedroom
            ? parseInt(this.bedroom.minBedroom.toString())
            : undefined;
        const end = this.bedroom.maxBedroom
            ? parseInt(this.bedroom.maxBedroom.toString())
            : undefined;
        if ((init && init > 0) || (end && end > 0)) value.push(init || 0);
        if (end && end > 0) value.push(end);
        return value;
    }
}
