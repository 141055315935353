


















import { Component, Mixins, Watch, Prop, Emit } from 'vue-property-decorator';
import FilterMixin from '@/mixins/filter';
import { typeProject } from '@/filters/type-project';

@Component
export default class ProjectType extends Mixins(FilterMixin) {
    @Prop({ default: true, type: Boolean })
    private readonly filterWhenChanging!: boolean;

    private projectTypes: number[] = [];
    
    private types = [
        { value: 1, label: 'Departamento' },
        { value: 2, label: 'Oficina' }
        // { value: 3, label: "Casa" },
        // { value: 4, label: "Lote" }
    ];

    get value() {
        return this.projectTypes;
    }

    set value(value: number[]) {
        if (this.filterWhenChanging) {
            this.setFilter({ projectTypes: value });
        } else {
            this.projectTypes = value;
        }
    }

    @Watch('filters.projectTypes', {
        immediate: true,
        deep: true
    })
    filter(value: number[]) {
        this.projectTypes = value;
    }

    @Watch('projectTypes', {
        immediate: true,
        deep: true
    })
    handleValue() {
        let label = '';
        if (this.projectTypes.length === this.types.length) {
            label = 'Todos';
        } else if (this.projectTypes.length > 0) {
            label = this.projectTypes.map((x) => typeProject(x)).join(', ');
        }

        this.filterString(label);
    }

    @Emit() filterString(label: string) {
        return { value: this.projectTypes, label };
    }

    clear() {
        this.setFilter({ projectTypes: [] });
    }

    changeFilter() {
        this.setFilter({ projectTypes: this.projectTypes });
    }
}
