




















































import { Component, Prop, Watch, Mixins } from 'vue-property-decorator';
import { projectModule } from '@/store/modules/project';
import { FilterProject as Filter } from '@/store/modules/project/types';
import ActivateBedroomFilterMixin from '@/mixins/activate-bedroom-filter';
import Price from './Filter/Price.vue';
import Bedroom from './Filter/Bedroom.vue';
import Bathroom from './Filter/Bathroom.vue';
import ProjectPhase from './Filter/ProjectPhase.vue';
import Area from './Filter/Area.vue';
import ViewFilter from './Filter/ViewFilter.vue';
import Financing from './Filter/Financing.vue';
import Applied from './Filter/Applied.vue';
import Location from './Filter/Location.vue';
import ProjectType from './Filter/ProjectType.vue';
import TypologyTypes from './Filter/TypologyTypes.vue';
import FilterMixin from '@/mixins/filter';
import Condition from './Filter/Condition.vue';
import Commission from './Filter/Commission.vue';
import Avatar from '@/components/Header/Avatar.vue';

@Component({
    components: {
        Price,
        Area,
        Bedroom,
        Bathroom,
        ProjectPhase,
        ViewFilter,
        Financing,
        Applied,
        Location,
        ProjectType,
        TypologyTypes,
        Condition,
        Commission,
        Avatar
    }
})
export default class FilterProject extends Mixins(
    FilterMixin,
    ActivateBedroomFilterMixin
) {
    private activeNames: number[] = [];

    get dialog() {
        return projectModule.dialogs;
    }

    mounted() {
        this.activeNames = this.dialog.filter
            ? []
            : Array.from({ length: 8 }, (v, i) => i);
    }
}
