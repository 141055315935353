










































































































































































































































































































































































































































import { Component, Ref, Watch, Mixins } from 'vue-property-decorator';
import { projectModule } from '@/store/modules/project';
import { savedSearchModule } from '@/store/modules/savedSearch';
import Project from '@/components/Project';
import FilterProject from '@/components/FilterProject.vue';
import ButtonsOfSendEmail from '@/components/ButtonsOfSendEmail.vue';
import Avatar from '@/components/Header/Avatar.vue';
import { appointmentModule } from '@/store/modules/appointment';
import Applied from '@/components/Filter/Applied.vue';
import MobileFilter from '@/components/MobileFilter.vue';
import ButtonSendEmail from '@/components/ButtonSendEmail.vue';
import Collapse from '@/components/Collapse.vue';
import Map from '@/components/Map.vue';
import ProjectDetail from '@/components/Project/ProjectDetail.vue';
import { agentModule } from '@/store/modules/agent';
import ChangeViewMixin from '@/mixins/change-view';
import ActivateBedroomFilterMixin from '@/mixins/activate-bedroom-filter';
import { OrderType } from '@/store/modules/project/types';
import ResizeMixin from '@/mixins/size';

@Component({
    components: {
        Project,
        FilterProject,
        ButtonsOfSendEmail,
        DialogSaveSearch: () => import('@/components/DialogSaveSearch.vue'),
        DialogSendEmail: () => import('@/components/DialogSendEmail.vue'),
        RegisterAppointment: () =>
            import('@/components/DialogAppointment/RegisterAppointment.vue'),
        Applied,
        MobileFilter,
        ButtonSendEmail,
        Avatar,
        Collapse,
        Map,
        ProjectDetail,
        Notice: () => import('@/components/Notice.vue')
    }
})
export default class ProjectSearch extends Mixins(
    ChangeViewMixin,
    ActivateBedroomFilterMixin,
    ResizeMixin
) {
    mobileBanner = true;
    resize() {
        this.mobileBanner = window.innerWidth <= 992;
    }
    @Ref() readonly filterReference!: FilterProject;
    @Ref() readonly main!: any;

    hideFilter = false;
    selectedProject: string | null = null;
    showNotice = false;

    @Watch('projects', {
        deep: true
    })
    resetScroll({ notResetScroll }: { notResetScroll: boolean }) {
        if (notResetScroll !== undefined && notResetScroll === false) {
            this.main.$el.scrollTop = 0;
        }
    }

    get dialogAppointment() {
        return appointmentModule.dialog;
    }

    get selectProjects() {
        return projectModule.selectProjects;
    }

    get savedSearch() {
        return savedSearchModule.savedSearchSelected;
    }

    get dialogSavedSearch() {
        return savedSearchModule.dialog;
    }

    set dialogSavedSearch(value: boolean) {
        savedSearchModule.closeOrOpenDialog();
    }

    get currentPage() {
        return projectModule.filter.page;
    }

    get view() {
        return projectModule.view;
    }

    set view(value: 'list' | 'map' | 'detail') {
        this.selectedProject = null;
        this.setView(value);
    }

    get projects() {
        return projectModule.projects;
    }

    get emptyState() {
        return projectModule.emptyState;
    }

    get dialogs() {
        return projectModule.dialogs;
    }

    get filters() {
        return projectModule.filter;
    }

    get options() {
        return projectModule.order;
    }

    handleChange(value: OrderType) {
        this.filterReference.setFilter({ orderBy: value });
        if (this.dialogs.orderBy) {
            projectModule.closeOrOpenDialog('orderBy');
        }
    }

    created() {
        const vh = window.innerHeight * 0.01 * 100;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    mounted() {
        projectModule.getProjects({
            query: this.$route.query,
            idSavedSearch: this.$route.params.id,
            requestInitial: true
        });
        if (
            !agentModule.agent?.politics?.find(({ url }) => url === '/search')
        ) {
            this.showNotice = true;
        }
        this.$socket.on('project_change', this.handleProjectChange);
        this.$socket.on('typology_change', this.handleTypologyChange);
        this.resize();
    }

    handleProjectChange(data?: { _id: string; condition_pago: number }) {
        if (
            data &&
            projectModule.projects.data.find(({ _id }) => _id === data._id)
        ) {
            appointmentModule.updatePaymentCondition(data.condition_pago);
            projectModule.updateOne(data);
        }
    }

    handleTypologyChange(data?: {
        _id: string;
        comisionTipologia: number;
        mongo_projectId: string;
    }) {
        if (data) {
            appointmentModule.updateCommission(data.comisionTipologia);
            projectModule.updateTypology(data);
        }
    }

    beforeDestroy() {
        this.$socket.off('project_change', this.handleProjectChange);
        this.$socket.off('typology_change', this.handleTypologyChange);
    }

    clickProject({ _id }: { _id: string }) {
        this.selectedProject = _id;
        const element = document.getElementById(_id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }

    handlePage() {
        projectModule.getProjectsByScroll(this.filters.page + 1);
    }
}
