




































import { Component, Mixins, Watch, Emit } from 'vue-property-decorator';
import FilterMixin from '@/mixins/filter';

@Component
export default class Bathroom extends Mixins(FilterMixin) {
    bathroom: { minBathroom?: number; maxBathroom?: number } = {
        minBathroom: 0,
        maxBathroom: 0
    };

    @Watch('filters.bathrooms', {
        immediate: true,
        deep: true
    })
    filter([minBathroom, maxBathroom]: number[]) {
        this.bathroom = {
            minBathroom: minBathroom < 1 ? undefined : minBathroom,
            maxBathroom: maxBathroom
        };
    }

    @Watch('bathroom', {
        immediate: true,
        deep: true
    })
    handleValue({ minBathroom, maxBathroom }: any) {
        let value = '';
        const [min, max] = this.parseValue();
        if (min > 0 && !max) {
            value = `Desde ${min} baños`;
        } else if (!min && max) {
            value = `Hasta ${max} baños`;
        } else if (min && max) {
            value = `${min} - ${max} baños`;
        }

        this.filterString(value);
    }

    @Emit() filterString(label: string) {
        return {
            value: this.parseValue(),
            label
        };
    }

    clear() {
        this.setFilter({
            bathrooms: []
        });
    }

    changeMin() {
        if (this.bathroom.minBathroom) {
            this.bathroom.minBathroom =
                this.bathroom.minBathroom < 0 || this.bathroom.minBathroom > 10
                    ? undefined
                    : this.bathroom.minBathroom;
        }
    }

    changeMax() {
        if (this.bathroom.maxBathroom) {
            this.bathroom.maxBathroom =
                this.bathroom.maxBathroom < 0 || this.bathroom.maxBathroom > 10
                    ? undefined
                    : this.bathroom.maxBathroom;
        }
    }

    changeFilter() {
        this.setFilter({
            bathrooms: this.parseValue()
        });
    }

    parseValue() {
        const value = [];
        const init = this.bathroom.minBathroom
            ? parseInt(this.bathroom.minBathroom.toString())
            : undefined;
        const end = this.bathroom.maxBathroom
            ? parseInt(this.bathroom.maxBathroom.toString())
            : undefined;

        if ((init && init > 0) || (end && end > 0)) value.push(init || 0);
        if (end && end > 0) value.push(end);
        return value;
    }
}
