








































import { Component, Mixins, Watch, Emit } from 'vue-property-decorator';
import FilterMixin from '@/mixins/filter';
import { FilterProject } from '@/store/modules/project/types';
import { Money } from 'v-money';

@Component({
    components: { Money }
})
export default class Price extends Mixins(FilterMixin) {
    private money = {
        thousands: ',',
        precision: 0
    };

    get sizeClass() {
        return `el-input--${this.size}`;
    }

    private price: {
        typeCurrency: 1 | 2;
        minPrice: number | string;
        maxPrice: number | string;
    } = {
        typeCurrency: 1,
        minPrice: '',
        maxPrice: ''
    };

    @Watch('filters', {
        immediate: true,
        deep: true
    })
    filter({ typeCurrency, price: [minPrice, maxPrice] }: FilterProject) {
        this.price = {
            typeCurrency: typeCurrency || 1,
            minPrice: minPrice || 0,
            maxPrice: maxPrice || 0
        };
    }

    @Watch('price', {
        immediate: true,
        deep: true
    })
    handleValue() {
        const price = this.parseValue();

        let label = '';
        if (price[0] > 0 && !price[1]) {
            label = `Desde ${price[0]}`;
        } else if (!price[0] && price[1]) {
            label = `Hasta ${price[1]}`;
        } else if (price[0] && price[1]) {
            label = `${price[0]} - ${price[1]}`;
        }

        this.filterString(label, {
            typeCurrency: this.price.typeCurrency,
            price: price
        });
    }

    @Emit() filterString(label: string, value: any) {
        return {
            value,
            label,
            suffixes: this.price.typeCurrency === 1 ? '(S/)' : '($)'
        };
    }

    clear() {
        this.setFilter({ typeCurrency: 1, price: [] });
    }

    changeFilter() {
        const price = this.parseValue();
        this.setFilter({
            typeCurrency: this.price.typeCurrency,
            price: price
        });
    }

    private parseValue() {
        const value = [];
        const minPrice =
            typeof this.price.minPrice === 'string'
                ? parseFloat(
                      (this.price.minPrice as string).replace(
                          this.money.thousands,
                          ''
                      )
                  )
                : this.price.minPrice;
        const maxPrice =
            typeof this.price.maxPrice === 'string'
                ? parseFloat(
                      (this.price.maxPrice as string).replace(
                          this.money.thousands,
                          ''
                      )
                  )
                : this.price.maxPrice;
        if ((minPrice && minPrice > 0) || maxPrice > 0)
            value.push(minPrice || 0);
        if (maxPrice && maxPrice > 0) value.push(maxPrice);
        return value;
    }
}
