import { Vue, Component, Prop } from 'vue-property-decorator';
import { projectModule } from '@/store/modules/project';
import { TypesUbigeo, Ubigeo } from '@/interfaces/ubigeo';
import { FilterProject } from '../store/modules/project/types';

@Component
export default class FilterMixin extends Vue {
    @Prop({ default: 'mini', type: String }) readonly size!: string;
    @Prop({ default: '', type: String }) readonly customClass!: string;
    @Prop({ default: false, type: Boolean }) readonly showTitle!: boolean;

    get dialog() {
        return projectModule.dialogs;
    }

    get filters() {
        return projectModule.filter;
    }

    setFilter(data: Partial<FilterProject>): void {
        if (this.filters.only && this.filters.only.length > 0) {
            projectModule.closeOrOpenDialog('cleanFilters');
            projectModule.setSearchFilters(data);
        } else {
            projectModule.activeOrDisabledChecked(false);
            projectModule.clearSelectedProjects();
            projectModule.setFilter(data);
            this.setQuery(data);
        }
    }

    setQuery(query: any) {
        this.$router.push({
            query: { ...this.$route.query, ...query }
        });
    }

    parseUbigeo(element: string): Ubigeo {
        const [name, id, tipo] = element.split('##');
        return { id, name, tipo: tipo as TypesUbigeo };
    }

    cleanFilters() {
        this.setFilter({
            price: [],
            bedrooms: [],
            bathrooms: [],
            meters: [],
            projectTypes: [1, 2],
            typologyTypes: [],
            projectPhases: [],
            financeBanks: [],
            orientation: [],
            locations: [],
            departamento: [],
            distrito: [],
            provincia: [],
            urbanization_id: []
        });
    }
}
