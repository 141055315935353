































































































import { Component, Mixins } from 'vue-property-decorator';
import ProjectMixin from '@/mixins/project';
import { projectPhase } from '@/filters/project-phase';

@Component({
    filters: { projectPhase }
})
export default class ProjectMap extends Mixins(ProjectMixin) {}
