export function phaseProject(value?: number): string | undefined {
    if (!value) return '';
    let type = '';
    switch (value) {
        case 1:
            type = 'En Planos';
            break;
        case 2:
            type = 'En Construcción';
            break;
        case 3:
            type = 'Entrega Inmediata';
            break;
        default:
            type = '';
            break;
    }
    return type;
}
