

















import { Component, Mixins, Watch, Prop, Emit } from 'vue-property-decorator';
import FilterMixin from '@/mixins/filter';
import { typeModel } from '@/filters/type-model';

@Component
export default class TypologyTypes extends Mixins(FilterMixin) {
    @Prop({ default: true, type: Boolean })
    private readonly filterWhenChanging!: boolean;

    private typologyTypes: number[] = [];

    private types = [
        { value: 3, label: 'Flat' },
        { value: 1, label: 'Duplex' },
        { value: 2, label: 'Triplex' }
    ];

    get value() {
        return this.typologyTypes;
    }

    set value(value: number[]) {
        if (this.filterWhenChanging) {
            this.setFilter({ typologyTypes: value });
        } else {
            this.typologyTypes = value;
        }
    }

    @Watch('filters.typologyTypes', {
        immediate: true,
        deep: true
    })
    filter(value: number[]) {
        this.typologyTypes = value;
    }

    @Watch('typologyTypes', {
        immediate: true,
        deep: true
    })
    handleValue() {
        let label = '';
        if (this.typologyTypes.length === this.types.length) {
            label = 'Todos';
        } else if (this.typologyTypes.length > 0) {
            label = this.typologyTypes.map((x) => typeModel(x)).join(', ');
        }

        this.filterString(label);
    }

    @Emit() filterString(label: string) {
        return { value: this.typologyTypes, label };
    }

    clear() {
        this.setFilter({ typologyTypes: [] });
    }

    changeFilter() {
        this.setFilter({ typologyTypes: this.typologyTypes });
    }
}
