














































































































































































import { Component, Prop, Mixins } from 'vue-property-decorator';
import { projectModule } from '@/store/modules/project';
import { savedSearchModule } from '@/store/modules/savedSearch';
import ChangeViewMixin from '@/mixins/change-view';

@Component
export default class ButtonSendEmail extends Mixins(ChangeViewMixin) {
    @Prop({ default: 'd-lg-block px-xl-3 d-none' })
    private classButton!: string;

    @Prop({ default: 'small' })
    private size!: string;

    @Prop({ default: 'primary' })
    private type!: string;

    @Prop({ default: false })
    private mobile!: boolean;

    get projectName() {
        return !projectModule.unselect.projectName;
    }

    set projectName(value: boolean) {
        projectModule.setUnselect({ projectName: !value });
    }

    get address() {
        return !projectModule.unselect.address;
    }

    set address(value: boolean) {
        projectModule.setUnselect({ address: !value });
    }

    get selectProjects() {
        return projectModule.selectProjects;
    }

    get dialog() {
        return projectModule.dialogs;
    }

    get filters() {
        return projectModule.filter;
    }

    handleClick(value: any) {
        this.setView(value);
    }

    openDialogSavedSearch() {
        savedSearchModule.closeOrOpenDialog();
    }

    activeOrDisabledChecked() {
        projectModule.activeOrDisabledChecked();
    }

    close(key: any) {
        projectModule.closeOrOpenDialog(key);
    }
}
