





















































































import { Component, Prop, Vue, Emit, Watch } from 'vue-property-decorator';
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer, LMarker, LIcon, LControlZoom, LPopup } from 'vue2-leaflet';
import L from 'leaflet';
/*import {
    MglMap,
    MglMarker,
    MglPopup,
    MglFullscreenControl,
    MglNavigationControl
} from 'vue-mapbox';*/
import ProjectMap from '@/components/Project/ProjectMap.vue';
import { projectModule } from '@/store/modules/project';
/*import mapboxgl from 'mapbox-gl';*/

@Component({
    components: {
        /*MglMap,
        MglMarker,
        MglPopup,
        MglFullscreenControl,
        MglNavigationControl,*/
        ProjectMap,
        LMap,
        LTileLayer,
        LMarker,
        LIcon,
        LControlZoom,
        LPopup
    }
})
export default class Map extends Vue {
    getPosition(coordinates: any) {
        return [coordinates[1], coordinates[0]];
    }
    data() {
        return {
            url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
            zoom: 12,
        };
    }
    @Prop({ required: true }) readonly projects!: any[];
    @Prop({ type: Boolean }) readonly hideFilter!: boolean;
    @Prop({ type: Boolean, default: false })
    readonly hideButton!: boolean;
    private hide = false;
    //private center = [-10.289967, -75.915152];
    private center = [-12.0102735, -77.0702289];
    $refs!: {
        map: any;
    };

    get view() {
        return projectModule.view;
    }

    get filter() {
        return projectModule.filter;
    }

    @Watch('projects', {
        deep: true,
        immediate: true
    })
    handleCenter() {
        if (
            this.projects &&
            this.projects.length > 0 &&
            this.$refs.map 
            /*&& this.$refs.map.map*/
        ) {
            /*const bounds = new mapboxgl.LngLatBounds();
            for (const { coordinates } of this.projects) {
                if (coordinates && coordinates.length > 0) {
                    const ll = new mapboxgl.LngLat(
                        coordinates[0],
                        coordinates[1]
                    );
                    bounds.extend(ll);
                }
            }
            this.$refs.map.map.fitBounds(bounds, {
                padding: 50,
                maxZoom: 12
            });*/
            const bound_marker = [];
            for (const { coordinates } of this.projects) {
                if (coordinates && coordinates.length > 0) {
                    if(coordinates[1] && coordinates[0] && 
                    coordinates[1] != null && coordinates[0] != null) {
                        bound_marker.push([coordinates[1], coordinates[0]]);
                    }
                    
                }
            }
            const bounds = bound_marker.reduce(function (bounds, coord) {
                return bounds.extend(coord);
            }, L.latLngBounds([-12.0102735, -77.0702289], [-12.0102735, -77.0702289]));
            this.$refs.map.fitBounds(bounds, { padding: [50, 50], maxZoom: 12 });
        }
    }

    @Watch('hideFilter', {
        deep: true,
        immediate: true
    })
    handleFilter(value: boolean) {
        this.hide = value;
    }

    @Watch('view', {
        deep: true
    })
    handleView(view: string) {
        if (view === 'map') {
            this.resize();
            if (!projectModule.projects.maximumLimit) {
                projectModule.setFilter({ all: true, page: 1 });
            }
        }
    }

    @Emit()
    clickProject(project: any, { marker }: any) {
        /*this.$refs.map.map.flyTo({
            center: [marker._lngLat.lng, marker._lngLat.lat]
        });*/
        this.$refs.map.mapObject.flyTo([project.coordinates[1], project.coordinates[0]], 12);
        return project;
    }

    @Emit()
    hideOrShow() {
        this.resize();
        return !this.hide;
    }

    resize() {
        /*if (this.$refs.map?.map) {
            this.$nextTick().then(() => this.$refs.map.map.resize());
        }*/
        if (this.$refs.map) {
            this.$nextTick().then(() => this.$refs.map.mapObject.invalidateSize());
        }
    }
}
