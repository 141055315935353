













import { Component, Mixins, Prop } from 'vue-property-decorator';
import FilterMixin from '@/mixins/filter';
import AutoComplete from '../AutoComplete.vue';
import { projectModule } from '@/store/modules/project';
import { Ubigeo } from '@/interfaces/ubigeo';
import { FilterProject } from '@/store/modules/project/types';
import { TypesUbigeo } from '@/interfaces/ubigeo';

type FilterProjectUbigeo = Pick<
    FilterProject,
    'distrito' | 'provincia' | 'urbanization_id' | 'departamento' | 'locations'
>;

@Component({
    components: { AutoComplete }
})
export default class Location extends Mixins(FilterMixin) {
    @Prop({ type: String, default: 'textarea' }) private readonly type!: string;
    @Prop({ type: Number, default: 2 }) private readonly rows!: number;

    getLocations(queryString: string, cb: Function) {
        projectModule.getLocations(queryString).then((data) => {
            cb(data);
        });
    }

    selectLocation(location: Ubigeo) {
        const locations = location.name.split(', ');
        const filters: FilterProject = JSON.parse(JSON.stringify(this.filters)); // JSON Clone
        const filter = filters.locations
            .filter((location) => {
                const { name } = this.parseUbigeo(location);
                const names = name.split(', ');
                return locations.includes(names[0]);
            })
            .reduce(this.removeLocation, {
                locations: filters.locations,
                departamento: filters.departamento,
                distrito: filters.distrito,
                provincia: filters.provincia,
                urbanization_id: filters.urbanization_id
            });

        filter[location.tipo].push(parseInt(location.id));
        filter.locations.push(
            `${location.name} ##${location.id} ##${location.tipo}`
        );
        this.setFilter(filter);
    }

    private removeLocation(
        filter: FilterProjectUbigeo,
        ubigeo: string
    ): FilterProjectUbigeo {
        const { tipo, id } = this.parseUbigeo(ubigeo);
        return {
            ...filter,
            [tipo]: filter[tipo].filter((e) => e !== parseInt(id)),
            locations: filter.locations.filter(
                (location) => location !== ubigeo
            )
        };
    }
}
