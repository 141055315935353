





































import { Component, Mixins, Watch, Emit } from 'vue-property-decorator';
import FilterMixin from '@/mixins/filter';

@Component
export default class Area extends Mixins(FilterMixin) {
    private area: { minMeters?: number; maxMeters: number } = {
        minMeters: 0,
        maxMeters: 0
    };

    @Watch('filters.meters', {
        immediate: true,
        deep: true
    })
    filter([minMeters, maxMeters]: number[]) {
        this.area = {
            minMeters: minMeters < 1 ? undefined : minMeters,
            maxMeters: maxMeters
        };
    }

    @Watch('area', {
        immediate: true,
        deep: true
    })
    handleArea({ minMeters, maxMeters }: any) {
        let value = '';
        const [min, max] = this.parseValue();
        if (min > 0 && !max) {
            value = `Desde ${min} m2`;
        } else if (!min && max) {
            value = `Hasta ${max} m2`;
        } else if (min && max) {
            value = `${min} - ${max} m2`;
        }

        this.filterString(value);
    }

    @Emit() filterString(label: string) {
        return { value: this.parseValue(), label };
    }

    clear() {
        this.setFilter({
            meters: []
        });
    }

    changeFilter() {
        this.setFilter({
            meters: this.parseValue()
        });
    }

    parseValue() {
        const value = [];
        const init = this.area.minMeters
            ? parseFloat(this.area.minMeters.toString())
            : undefined;
        const end = this.area.maxMeters
            ? parseFloat(this.area.maxMeters.toString())
            : undefined;
        if ((init && init > 0) || (end && end > 0)) value.push(init || 0);
        if (end && end > 0) value.push(end);
        return value;
    }
}
