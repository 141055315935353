

























import { Component, Mixins, Watch, Emit } from 'vue-property-decorator';
import FilterMixin from '@/mixins/filter';

@Component
export default class Financing extends Mixins(FilterMixin) {
    private banks: string[] = [];
    private finances = [
        { value: 'BCP', label: 'BCP' },
        { value: 'BBVA', label: 'BBVA' },
        { value: 'SCOTIABANK', label: 'SCOTIABANK' },
        { value: 'Financiero', label: 'FINANCIERO' },
        { value: 'BANBIF' },
        { value: 'GNB', label: 'Banco GNB' },
        { value: 'Comercio', label: 'BANCO DE COMERCIO' },
        { value: 'INTERBANK' }
    ];

    @Watch('filters.financeBanks', {
        immediate: true,
        deep: true
    })
    filter(value: string[]) {
        this.banks = value;
    }

    @Watch('banks', {
        immediate: true,
        deep: true
    })
    handleValue() {
        let label = '';
        if (this.banks.length === this.finances.length) {
            label = 'Todos';
        } else if (this.banks.length > 0) {
            label = this.banks.join(', ');
        }
        this.filterString(label);
    }

    @Emit() filterString(label: string) {
        return { value: this.banks, label };
    }

    clear() {
        this.setFilter({
            financeBanks: []
        });
    }

    changeFilter() {
        this.setFilter({
            financeBanks: this.banks
        });
    }
}
