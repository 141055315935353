












































































































































































































































import { Component, Mixins, Prop } from 'vue-property-decorator';
import FilterMixin from '@/mixins/filter';
import { typeProject } from '@/filters/type-project';
import { typeModel } from '@/filters/type-model';
import { phaseProject } from '@/filters/phase-project';
import { location } from '@/filters/location';
import { projectModule } from '@/store/modules/project';
import { FilterProject } from '@/store/modules/project/types';

@Component({
    filters: {
        typeProject,
        typeModel,
        phaseProject,
        location
    }
})
export default class Applied extends Mixins(FilterMixin) {
    get emptyFilter(): boolean {
        return (
            Object.keys(this.filters).filter(
                (key) =>
                    !['page', 'orderBy'].includes(key) &&
                    (this.filters as any)[key] &&
                    (this.filters as any)[key].length > 0
            ).length > 0
        );
    }

    clean() {
        this.cleanFilters();
    }

    private textPrice() {
        const filters = this.filters;
        const typeCurrency = this.filters['typeCurrency'];
        let text = '';
        if (filters.price[0] > 0 && !filters.price[1]) {
            text = ` Desde ${
                typeCurrency !== 1 ? '$' : 'S/.'
            } ${filters.price[0].toLocaleString('en-US')}`;
        } else if (filters.price[0] < 1 && filters.price[1]) {
            text = ` Hasta ${
                typeCurrency !== 1 ? '$' : 'S/.'
            } ${filters.price[1].toLocaleString('en-US')}`;
        } else {
            text = ` ${
                typeCurrency !== 1 ? '$' : 'S/.'
            } ${filters.price[0].toLocaleString('en-US')} - ${
                typeCurrency !== 1 ? '$' : 'S/.'
            } ${filters.price[1].toLocaleString('en-US')}`;
        }
        return text;
    }

    private removeLocation(ubigeo: string) {
        const { tipo, id } = this.parseUbigeo(ubigeo);
        this.setFilter({
            locations: this.filters.locations.filter(
                (location) => location !== ubigeo
            ),
            [tipo]: this.filters[tipo].filter((e) => e !== parseInt(id))
        });
    }
}
