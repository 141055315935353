








import { Component, Prop, Vue, Emit } from 'vue-property-decorator';

@Component
export default class Collapse extends Vue {
    @Prop({ type: Boolean }) readonly value!: string;
    get actives() {
        return this.value ? [1] : [2];
    }

    set actives(value: number | number[]) {
        this.input(value === 1);
    }

    @Emit()
    input(event: boolean): boolean {
        return event;
    }
}
