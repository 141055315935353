






























































































import { Component, Mixins, Emit, Prop } from 'vue-property-decorator';
import { projectModule } from '@/store/modules/project';
import { savedSearchModule } from '@/store/modules/savedSearch';
import Location from '@/components/Filter/Location.vue';
import ButtonSendEmail from '@/components/ButtonSendEmail.vue';
import ChangeViewMixin from '@/mixins/change-view';

@Component({
    components: { Location, ButtonSendEmail }
})
export default class ButtonsOfSendEmail extends Mixins(ChangeViewMixin) {
    @Prop({ default: false }) private actions!: boolean;

    get link() {
        if (!savedSearchModule.savedSearchSelected) return '/discarded';
        return `/discarded-saved-search/${savedSearchModule.savedSearchSelected._id}`;
    }

    get view() {
        return projectModule.view;
    }

    get dialog() {
        return projectModule.dialogs;
    }

    get filters() {
        return projectModule.filter;
    }

    handleClick(value: any) {
        this.setView(value);
    }

    openDialogSavedSearch() {
        savedSearchModule.closeOrOpenDialog();
    }

    close(key: any) {
        projectModule.closeOrOpenDialog(key);
    }
}
